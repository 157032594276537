// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultTheme = require('tailwindcss/defaultTheme');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { contentGlobs } = require('./webpack/purgecss.config');

module.exports = {
  presets: [require('@videoblocks/storywind/tailwind.config').default],
  /**
   * Disabling some tailwindcss plugins to avoid overriding existing
   * bootstrap styles while transitioning from bootstrap to tailwindcss
   *
   * https://tailwindcss.com/docs/configuration#core-plugins
   */
  corePlugins: {
    preflight: false,
    container: false,
  },
  /**
   * Disabling tailwind's purgeCSS since this is being duplicated in webpack.prod.js,
   * but leaving this here in case we want to turn this back on or we decide to
   * remove PurgeCSS from stockblocks.
   */
  purge: {
    enabled: false,
    content: contentGlobs,
  },
  /**
   * We'll want to disable important here once specificity issues with bootstrap have been resolved
   * (or when bootstrap has been removed completely)
   */
  important: true, // https://tailwindcss.com/docs/configuration#important
  theme: {
    extend: {
      borderRadius: {
        '2.5xl': '1.25rem',
      },
      boxShadow: {
        'plan-card':
          '0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.15), 0 1px 0.25rem 0 rgba(0, 0, 0, 0.2)',
        dropdown: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        popover:
          '0 1.5rem 3rem 0 rgba(0, 0, 0, 0.15), 0 0.5rem 0.75rem 0 rgba(0, 0, 0, 0.20)',
        fixedShadow: '0 0 10px 1px #666666',
        'audio-card': '0 0 8px 2px rgb(0 0 0 / 0.1)',
      },
      colors: {
        gray: {
          110: '#f5f5f5',
          140: '#E7E7E7',
          150: '#EFEFEF',
          250: '#B9BCC1',
          550: '#626C78',
          650: '#666666',
          800: '#36414D',
          850: '#a6a6a6',
          950: '#1A1C1E',
        },
        yellow: {
          600: '#fdce1d',
        },
        blue: {
          1000: '#0066cc',
        },
        purple: {
          900: '#00005B',
        },
        // These are old colors to help with the transition to tailwind, but are deprecated.
        // Any new development or refactor should use one of the colors in our design system above instead.
        old: {
          silver: '#dbdbdb',
        },
        transparent: 'transparent',
        current: 'currentColor',
      },
      fill: (theme) => ({
        green: theme('colors.green.500'),
        blue: theme('colors.blue.500'),
        gray: theme('colors.gray.500'),
        none: 'none',
      }),
      fontFamily: {
        // old way
        sans: ['inter', ...defaultTheme.fontFamily.sans],
        serif: ['polysans_median', ...defaultTheme.fontFamily.sans], // fall back to sans, we hate serif now
        // new way
        polysans: ['polysans_median', ...defaultTheme.fontFamily.sans],
        inter: ['inter', ...defaultTheme.fontFamily.sans],
      },
      fontWeight: {
        normal: 400,
        semibold: 600,
      },
      fontSize: {
        xxs: '.625rem',
        // Overriding storywind line height and font size.
        '3xl': ['2rem', '2.5rem'],
        '5xl': ['3rem', '3.5rem'],
        // Redefining default Tailwind size as Tailwind defaults are not currently functioning as detailed here, https://wearevideoblocks.slack.com/archives/C04B3GB6S/p1632854887233300
        '6xl': ['3.75rem', '1'],
        '7xl': ['4.5rem', '1'],
        '8xl': ['6rem', '1'],
        '9xl': ['8rem', '1'],
      },
      gridTemplateColumns: {
        'main-nav-3': 'max-content auto max-content',
        'main-nav-4': 'max-content max-content auto max-content',
      },
      gridTemplateRows: {
        10: 'repeat(10, minmax(0, 1fr))',
        11: 'repeat(11, minmax(0, 1fr))',
        12: 'repeat(12, minmax(0, 1fr))',
        13: 'repeat(13, minmax(0, 1fr))',
      },
      inset: {
        '1/10': '10%',
      },
      height: {
        11.5: '2.875rem',
        13: '3.25rem',
        33: '8.25rem',
        35: '8.75rem',
        100: '25rem',
        120: '30rem',
        128: '32rem',
        144: '36rem',
        160: '40rem',
        'min-content': 'min-content',
        '1/12': '8.333333%',
        '10vh': '10vh',
        '1/10': '10%',
        '9/10': '90%',
        '22/25': '88%',
        '3/25': '12%',
        fit: 'fit-content',
      },
      lineHeight: {
        2: '.5rem',
        12: '3rem',
        14: '3.5rem',
        16: '4.0rem',
      },
      margin: {
        5: '1.25rem',
      },
      minWidth: {
        6: '1.5rem',
        8: '2rem',
        10: '2.5rem',
        20: '5rem',
        28: '7rem',
        30: '7.5rem',
        38: '9.5rem',
        40: '10rem',
        200: '200px',
        600: '600px',
        '21vw': '21vw',
        316: '316px',
        330: '330px',
        348: '348px',
      },
      minHeight: {
        8: '2rem',
        9: '2.25rem',
        14: '3.5rem',
        100: '25rem',
        '50vh': '50vh',
      },
      maxHeight: {
        '9/10': '90%',
        '75vh': '75vh',
      },
      skew: {
        '-10': '-10deg',
        '-1.5': '-1.5deg',
        10: '10deg',
      },
      maxWidth: {
        11.5: '2.875rem',
        16: '4rem',
        50: '12.5rem',
        80: '20rem',
        '1/2': '50%',
        '3/4': '75%',
        '25vw': '25vw',
        '66vw': '66vw',
      },
      width: {
        11.5: '2.875rem',
        13: '3.25rem',
        18: '4.5rem',
        33: '8.25rem',
        38: '9.5rem',
        50: '12.5rem',
        61: '15.625rem',
        76: '19rem',
        80: '20rem',
        88: '22rem',
        94: '23.5rem',
        112: '28rem',
        128: '32rem',
        150: '37.5rem',
        240: '60rem',
        288: '72rem',
        fit: 'fit-content',
        '11/10': '110%',
        '32vw': '32vw',
        '21vw': '21vw',
        '553px': '553px',
        '500px': '500px',
        '720px': '720px',
      },
      zIndex: {
        '-1': '-1', // usage: -z-1
        // TODO: remove custom values and use built in z-indices with the appropriate stacking context instead
        999: '999',
        1000: '1000',
        1040: '1040',
        1050: '1050',
        9999: '9999',
      },
      spacing: {
        15: '3.375rem',
        // don't need these once we upgrade our tailwind version
        navHeaderHeight: '-7.5em',
        '3.75vh': '3.75vh',
        168: '168px',
        50: '50px',
      },
      transitionProperty: {
        width: 'width',
        height: 'height',
      },
      flexGrow: {
        2: 2,
      },
      scrollBehavior: ['smooth'],
    },
    screens: {
      'mobile-md': '375px',
      'ppro-sm': '425px',
      md: '672px',
      'maker-md': '768px', // FIXME: maker-landing.scss uses this non-standard breakpoint. Refactor as part of NUG-237?
      lg: '1024px',
      xl: '1200px',
      'storyboard-lg': '1320px', // This breakpoint exists to give space to the Storyboard AI button in the top nav. FLOW-923
      'ppro-lg': '1440px',
      '2xl': '1600px',
      '3xl': '1800px',
      '4xl': '2300px',
    },
  },
  variants: {
    extend: {
      borderWidth: ['first', 'hover', 'last'],
      cursor: ['disabled'],
      fontWeight: ['hover'],
      opacity: ['disabled'],
      rotate: ['group-hover'],
      visibility: ['group-hover'],
      backgroundColor: ['disabled'],
      scale: ['group-hover'],
    },
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/line-clamp'),
    //One-off fix to have the underline text-decoration-color match the font color
    //See https://stackoverflow.com/questions/61303798/how-can-i-change-the-underline-color-in-tailwind-css
    function ({ addUtilities }) {
      const extendUnderline = {
        '.underline': {
          textDecoration: 'underline',
          'text-decoration-color': 'currentColor',
        },
        // additional rule to override external-crutch.less styles
        '.hover:no-underline:hover': { textDecoration: 'none' },
      };
      addUtilities(extendUnderline);
    },
    // Plugin to hide scrollbar
    function ({ addComponents }) {
      addComponents({
        '.hide-scrollbars': {
          'scrollbar-width': 'none',
          '-ms-overflow-style': 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      });
    },
    //Mobile Nav plugin
    function ({ addUtilities }) {
      const newUtilities = {
        '.custom-scroll': {
          '-webkit-overflow-scrolling': 'touch',
          'scroll-snap-type': 'x proximity',
        },
      };

      addUtilities(newUtilities, ['responsive', 'hover']);
    },
    // Prominent Search underline style plugin
    function ({ addUtilities }) {
      const newUnderlineUtilities = {
        '.thick-3': {
          'text-decoration-thickness': '0.2rem',
        },
        '.offset-5': {
          'text-underline-offset': '5px',
        },
        // @color-primary
        '.text-decor-color-primary': {
          'text-decoration-color': 'yellow',
        },
      };

      addUtilities(newUnderlineUtilities, ['responsive', 'hover']);
    },
  ],
};

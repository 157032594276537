import { Builder, builder } from '@builder.io/react';
import { carouselConfig } from '@builder.io/widgets/dist/lib/components/Carousel.config';
import loadable from '@loadable/component';

import './components/blocks/Articles.builder';
import './components/blocks/AssetsCounter.builder';
import './components/blocks/AudioCardList.builder';
import './components/blocks/BrowseCategories.builder';
import './components/blocks/CardSection.builder';
import './components/blocks/ContentCarousel.builder';
import './components/blocks/DualMediaAndText.builder';
import './components/blocks/FAQ.builder';
import './components/blocks/FeaturedArtists.builder';
import './components/blocks/FooterHook.builder';
import './components/blocks/Genres.builder';
import './components/blocks/HomepageProminentSearch.builder';
import './components/blocks/HomepageTypewriter.builder';
import './components/blocks/Hubspot.builder';
import './components/blocks/ImageAndText.builder';
import './components/blocks/ImageCards.builder';
import './components/blocks/ImageContentGrid.builder';
import './components/blocks/Logos.builder';
import './components/blocks/MediaAndText.builder';
import './components/blocks/MediaHero.builder';
import './components/blocks/Moods.builder';
import './components/blocks/Pills.builder';
import './components/blocks/Pricing.builder';
import './components/blocks/SearchBar.builder';
import './components/blocks/SearchHero.builder';
import './components/blocks/SeoText.builder';
import './components/blocks/SeoTextAndImage.builder';
import './components/blocks/SiteMap.builder';
import './components/blocks/SiteMapCategory.builder';
import './components/blocks/Tabs.builder';
import './components/blocks/Testimonials.builder';
import './components/blocks/TextHero.builder';
import './components/blocks/ThankYou.builder';
import './components/blocks/VideoArticles.builder';
import './components/blocks/VideoCarousel.builder';
import './components/blocks/VideoContentGrid.builder';
import './components/blocks/legacy/Articles.builder';
import './components/blocks/legacy/BenefitsV1.builder';
import './components/blocks/legacy/DualMediaAndText.builder';
import './components/blocks/legacy/ImageCards.builder';
import './components/blocks/legacy/ImageHero.builder';
import './components/blocks/legacy/MediaAndText.builder';
import './components/blocks/legacy/Pills.builder';
import './components/blocks/legacy/Pricing.builder';
import './components/blocks/legacy/VideoContentGrid.builder';
import './components/blocks/legacy/VideoHero.builder';
import './components/blocks/vimeo/Vimeo.builder';
import './components/blocks/wistia/Wistia.builder';
import './components/blocks/youtube/Youtube.builder';
import './components/elements/FormattedDate.builder';

/**
 * Imports all custom blocks from builder-io/components
 */

builder.init(__BUILDER_PUBLIC_KEY__);

/*
 * Import Custom Builder Widgets
 */
Builder.registerComponent(
  loadable(() =>
    import('@builder.io/widgets/dist/lib/components/Carousel').then(
      (mod) => mod.CarouselComponent
    )
  ),
  carouselConfig
);

Builder.register('insertMenu', {
  name: 'Audio Components',
  items: [{ name: 'Featured Artists' }, { name: 'Genres' }, { name: 'Moods' }],
});

Builder.register('insertMenu', {
  name: 'Headers',
  items: [{ name: 'Media Hero' }, { name: 'Text Hero' }],
});

Builder.register('insertMenu', {
  name: 'Media & Text',
  items: [
    { name: 'Image And Text' },
    { name: 'Media And Text' },
    { name: 'Media And Text (Dual)' },
    { name: 'SEO Text And Image' },
    { name: 'SEO Text' },
  ],
});

Builder.register('insertMenu', {
  name: 'Stock Items',
  items: [
    { name: 'Video Content Grid' },
    { name: 'Audio Card List' },
    { name: 'Image Content Grid' },
  ],
});

Builder.register('insertMenu', {
  name: 'Other Components',
  items: [
    { name: 'Articles' },
    { name: 'Assets Counter' },
    { name: 'Browse Categories' },
    { name: 'Card Section' },
    { name: 'FAQ' },
    { name: 'Footer Hook' },
    { name: 'Hubspot' },
    { name: 'Image Cards' },
    { name: 'Logos' },
    { name: 'Pills' },
    { name: 'Pricing' },
    { name: 'Site Map' },
    { name: 'Site Map Category' },
    { name: 'Testimonials' },
    { name: 'Thank You' },
    { name: 'Video Articles' },
    { name: 'Video Carousel' },
    { name: 'Vimeo' },
    { name: 'Wistia' },
    { name: 'Youtube' },
    { name: 'Homepage Typewriter' },
    { name: 'Homepage Prominent Search' },
    { name: 'Search Hero' },
    { name: 'Content Carousel' },
    { name: 'Formatted Date' },
    { name: 'Search Bar' },
  ],
});

Builder.register('insertMenu', {
  name: 'Legacy Components',
  items: [
    { name: 'Articles (Legacy)' },
    { name: 'Benefits (v1)' },
    { name: 'Image Cards (Legacy)' },
    { name: 'Image Hero' },
    { name: 'Media And Text (Legacy)' },
    { name: 'Media And Text (Dual) Legacy' },
    { name: 'Pills (Legacy)' },
    { name: 'Pricing (Legacy)' },
    { name: 'Video Content Grid (Legacy)' },
    { name: 'Video Hero (Legacy)' },
  ],
});
